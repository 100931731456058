<template>
  <div class="barcode-pop">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" class="mt-5">
        <v-app-bar app :color="bgColor" elevation="0">
          <v-btn icon color="#fff" @click="$emit('close')">
            <v-icon medium>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="logo">
            <img src="@/assets/logo2.png" class="img-logo">
          </div>
        </v-app-bar>
        <v-card class="py-4 d-flex justify-center" elevation="0">
          <img :src="image" alt="barcode" class="barcode-image" width="100%">
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Barcode',
  props: {
    image: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState([
      'bgColor'
    ])
  }
}
</script>

<style scoped>
.barcode-pop {
  background-color: #fff;
  width: 100%;
  height: 100vh;
}

.barcode-image {
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.logo {
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 20;
}

.img-logo {
  width: 70%;
  max-width: 50px;
  cursor: pointer;
}
</style>
