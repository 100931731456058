<template>
  <div id="order-detail">
    <v-flex xs12 v-if="order">
      <h1 v-if="!isMobile">{{ $t('order.order-detail')}}</h1>
      <p v-if="!isMobile" class="text">{{ $t('order.subtitle2')}}</p>
      <p v-if="isMobile" class="title">{{ $t('order.order')}} {{ order.sales_header.sales_order_number }}</p>

      <div :class="isMobile ? 'mt-3 mb-3 ml-3' : 'mt-3 mb-3'">
        <v-layout wrap class="mb-3"> 
          <v-flex xs12 sm12 md6>
            <p class="subtitle-gray"> {{ $t('order.order-date')}} :  <span class="subtitle">{{ formatDate(order.sales_header.order_date) }} </span></p>
            <p v-if="!isMobile" class="subtitle-gray"> {{ $t('order.order-number')}} :  <span class="subtitle">{{ order.sales_header.sales_order_number }} </span></p>
            <p class="subtitle-gray">  {{ $t('order.resi-number')}} : 
              <span v-if="order.sales_header.no_resi"> {{order.sales_header.no_resi}} 
                <v-btn icon x-small
                  v-clipboard:copy="order.sales_header.no_resi"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </span>
              <span v-else> - </span>
            </p>
            <p class="subtitle-gray"> Status :  <span class="subtitle">{{ order.sales_header.status_message }} </span></p>
            <p class="subtitle-gray">  {{ $t('order.due-date')}} :  <span class="text-r"> <b>{{ order.payment.expired_at }}</b></span></p>
          </v-flex>
          <v-flex xs12 sm12 md6 :class="isMobile ? 'mt-5': ''" v-if="order.sales_header.status === 'Open'">
            <span class="subtitle uppercase">  {{ $t('order.payment')}} </span>
            <p class="text"> {{ order.payment.name }}  
              <span class="subtitle" v-if="order.payment.payment_type === 'MANUAL'"> {{ $t('order.manual')}} </span>
              <span class="subtitle" v-else> {{ $t('order.automatic')}} </span>
            </p>

            <div v-if="order.payment.payment_method_category === 'BANK TRANSFER'">
              <p class="subtitle-gray">
                {{ $t('order.account-name')}} : <span class="subtitle"> {{ order.payment.atas_nama}}</span>
              </p>
              <p class="subtitle-gray"> 
                {{ $t('order.account-number')}} :  <span class="subtitle">{{ order.payment.rekening }} </span>
                <v-btn icon x-small
                  v-clipboard:copy="order.payment.rekening"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </p>
              <p class="subtitle-gray mt-9" v-if="idBrand === '15'">
                {{ $t('order.do')}} <span class="subtitle pointer" @click="toPage('Confirm-Payment-id')"> {{ $t('payment-confirm.title2')}}</span> {{ $t('order.via-website')}}
              </p>
            </div>

            <p v-if="order.payment.payment_method_category === 'VIRTUAL-ACCOUNT'" class="subtitle-gray"> 
              {{ $t('order.virtual-account-number')}} :  <span class="subtitle">{{ order.payment.rekening }} </span>
              <v-btn icon x-small
                v-clipboard:copy="order.payment.rekening"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError">
                <v-icon> mdi-content-copy </v-icon>
              </v-btn>
            </p>

            <div style="text-align:center" v-if="order.payment.payment_method_category === 'E-WALLET'">
              <p class="subtitle mt-2"> {{ $t('order.click')}} </p>
              <v-btn v-if="order.payment.payment_method_code === 'gopay'" class="mb-2" tile color="green" dark width="200px" height="44px" @click="openLink()">
                <span class="btn-text"> {{ $t('order.open-gojek')}} </span>
              </v-btn>
              <v-btn v-else class="mb-2" tile color="#ff5000" dark width="200px" height="44px" @click="openLink()">
                <span class="btn-text"> {{ $t('order.open-shopee')}} </span>
              </v-btn>
              <div v-if="order.payment.payment_method_code === 'gopay'">
                <p class="subtitle mb-0 mt-2"> {{ $t('order.barcode-scan')}} :</p>
                <img v-bind:src="order.payment.qr_code" class="qr-code" alt="qr-code">
              </div>
            </div>

            <p> 
              <span class="subtitle">{{ $t('order.total')}} :  </span>
              <span class="text-r2"> {{ addComma(order.sales_header.total_bayar) }} IDR </span>
              <v-btn icon x-small
                v-clipboard:copy="order.sales_header.total_bayar"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError">
                <v-icon> mdi-content-copy </v-icon>
              </v-btn>
            </p>

            <p class="subtitle"> {{ $t('order.how-to-pay')}} : </p>
            <v-expansion-panels focusable class="mt-5 mb-5">
              <v-expansion-panel
                v-for="(item,i) in order.tutorial_payment"
                :key="i"
              >
                <v-expansion-panel-header>
                  <span class="subtitle-gray"> {{ item.judul }} </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span class="subtitle-gray pt-10" v-html="item.deskripsi"></span>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>

        <v-layout wrap class="mt-5 mb-3"> 
          <v-flex xs12 sm12 md6 class="mt-5">
            <span class="title uppercase"> Self Pick up </span> 
            <p class="text"> <b>{{ officeAddress.penerima }} </b></p>
            <p class="text"> {{ officeAddress.alamat_lengkap }}</p>
            <p class="text"> {{ officeAddress.subdistrict }}</p>
            <p class="text"> {{ officeAddress.city }} {{ officeAddress.kode_pos}}</p>
            <p class="text"> {{ officeAddress.province}}</p>
            <p class="text"> Indonesia </p>
            <!-- <p class="subtitle-gray"> {{ $t('order.phone')}} : {{ officeAddress.no_hp}}</p> -->
            <!-- <p v-if="order.sales_header.no_resi && isMobile" class="tracking-text mt-9" @click="tracking(order.sales_header.no_resi)"> LACAK PENGIRIMAN</p> -->

            <div style="text-align: center" v-if="order.shipping.barcode">
              <v-divider class="mt-4 mb-4 ml-2 mr-2"></v-divider>
              <p class="title uppercase"> QR CODE </p> 
                <img v-bind:src="order.shipping.barcode" class="barcode" alt="qr-code" @click="openBarcode">
              <p class="subtitle mb-4 mt-4"> Show QR code to cashier to claim your order!</p>
              <v-divider class="mt-4 mb-4 ml-2 mr-2"></v-divider>
            </div>
          
          </v-flex>
           <v-flex xs12 sm12 md6 class="order-detail mt-5">
            <span class="title uppercase"> {{ $t('order.detail')}}</span>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-layout wrap class="mt-5 mb-3" v-for="(item, i) in order.sales_line" :key="i">
              <v-flex xs5 sm5 md5 class="mt-1">
                <img v-bind:src="item.url_image" class="responsive" :alt="item.img1">
              </v-flex>
              <v-flex xs5 sm5 md7 class="mt-1">
                <p class="text"> {{ item.product_master_name }}</p>
                <p class="f-smaller mt-0"> {{ item.warna }} -  {{ item.ukuran }}</p>
                <table class="tbl">
                  <tr>
                    <td class="text"> {{ addComma(item.harga_jual) }} IDR</td>
                    <td class="text pr"> x{{ item.quantity }}</td>
                  </tr>
                </table>
              </v-flex>
            </v-layout>
            <v-divider class="mt-2 mb-2"></v-divider>
          </v-flex>
        </v-layout>

        <v-layout wrap class="mt-3 mb-3"> 
          <v-flex xs12 sm12 md6 class="mt-5" v-if="!isMobile">      
          </v-flex>
          <v-flex xs12 sm12 md6 class="mt-5">
            <div class="amount">
              <table class="tbl">
                <tr>
                  <td class="text"> Subtotal :</td>
                  <td class="text pr"> {{ addComma(order.sales_header.subtotal) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.shipping-fee')}} :</td>
                  <td class="text pr"> {{ addComma(order.sales_header.biaya_kirim) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.service-fee')}} :</td>
                  <td class="text pr"> {{ addComma(order.payment.fee) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.packing-fee')}} :</td>
                  <td class="text pr"> {{ addComma(order.sales_header.packing_fee) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> 
                    Voucher : <br>
                    <!-- {{ $t('order.promo') }} / Voucher : <br> -->
                    <span class="subtitle-gray"> {{order.sales_header.promo_code }}</span>
                  </td>
                  <td class="text-r pr"> - {{ addComma(order.sales_header.promo_value) }} IDR</td>
                </tr>
                <!-- <tr>
                  <td class="text"> 
                    {{ $t('order.unique')}} :
                  </td>
                  <td class="text pr"> {{ addComma(order.sales_header.kode_unik) }} IDR</td>
                </tr> -->
                <tr>
                  <td> <h3> TOTAL : </h3> </td>
                  <td class="pr"> <h3> {{ addComma(order.sales_header.total_bayar) }} IDR </h3> </td>
                </tr>
              </table>
            </div>
          </v-flex>
        </v-layout>

        <v-divider class="mt-2 mb-2"></v-divider>

      </div>
    </v-flex>
    <v-flex v-if="!order && finish">
      <p class="title" align="center"> Data Not Found </p>
    </v-flex>

    <v-dialog v-model="showBarcode" :fullscreen="true">
      <Barcode v-if="showBarcode" :image="order.shipping.barcode" @close="showBarcode= false"/>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import Barcode from '@/components/Barcode'

  export default {
    name: 'OrderDetail',
    components: { Barcode },
    computed: {
      ...mapState([
        'isMobile',
        'token',
        'officeAddress'
      ])
    },
    data () {
      return {
        finish: false,
        order: null,
        selectedResi: '',
        dialog: false,
        showBarcode: false
      }
    },
    methods: {
      onCopy (e) {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', 'You just copied: ' + e.text)
      },
      onError (e) {
        this.$store.commit('setSnackbar', true)
        this.$store.commit('setText', e)
      },
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name, params: { id: this.order.sales_header.id_sales_header}});
        }
      },
      openBarcode () {
        if (this.isMobile) {
          this.showBarcode = true
        }
      },
      tracking (data) {
        this.selectedResi = data
        setTimeout(() => { 
          this.dialog = true
        }, 200)
      },
      close () {
        this.dialog = false
      },
      openLink(){
        let routeData = this.order.payment.deeplink
        window.open(routeData, '_blank');
      },
      getHistoryDetail (id) {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios.get('/api/v1/member/order_history/' + id)
          .then(response => {
            this.order = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
            this.finish = true
          })
      }
    }, 
    mounted () {
      this.getHistoryDetail(this.$route.params.id)

      this.setMetaInfo(null, 'Purchase Detail')

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', 'DETAIL PESANAN')
    }
  }
</script>

<style scoped>
  .tbl {
    width: 100%;
  }

  .text {
    color: #000000;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-r {
    color: red;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-r2 {
    color: red;
    margin-bottom: 2px;
    font-size: 1rem !important;
    font-weight: bold;
  }

  .tracking-text {
    color: #808080;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
    cursor: pointer;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
    font-weight: bold;
  }

  .f-smaller {
    color: #808080;
    font-size: 0.7rem !important;
  }

  .subtitle-gray {
    color: #808080;
    margin-bottom: 0;
    font-size: 0.8rem !important;
  }

  .responsive {
    max-width: 125px !important;
    width: 100%;
    height: auto;
    position: relative;
    word-wrap: break-word;
  }

  .qr-code {
    max-width: 200px !important;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .barcode {
    max-width: 250px !important;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .pr {
    float: right;
  }

  .red {
    color: red;
  }

  .order-detail {
    max-height: 500px;
    overflow-y: auto;
  }

  .amount {
    border: 1px solid #808080;
    padding: 15px;
  }

  .pointer {
    cursor: pointer;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
